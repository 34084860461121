import { suisseIntl, suisseIntlMono } from '@1337-us/ui-fonts/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import './styles.css'
import { GoogleAnalyticsScripts } from '@1337-us/utils-analytics'

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>1337</title>

        <meta name="robots" content="index,follow" />
        <meta name="description" content="The best platform for operating AI influencers" />
        <meta property="og:title" content="1337" />
        <meta property="og:image" content="/ogimage.png?v=1337" />

        <meta name="facebook-domain-verification" content="b46b7t4bnqekwk4g0shzfu6rlial2y" />

        <link rel="icon" href="/favicon.ico?v=1337" />
        <link rel="icon" href="/favicon.png?v=1337" type="image/png" />
      </Head>

      <main
        className={`app-main overflow-hidden ${suisseIntl.variable} ${suisseIntlMono.variable}`}
      >
        <GoogleAnalyticsScripts measurementId={process.env.NEXT_PUBLIC_GA_ID as string} />
        <Component {...pageProps} />
      </main>
    </>
  )
}

export default CustomApp
