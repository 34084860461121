/* eslint-disable @next/next/no-img-element */
import { useAnalyticsPageView } from '@1337-us/utils-analytics'
import Lenis from '@studio-freight/lenis'
import { useEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useScramble } from 'use-scramble'
import SplitType from 'split-type'
import * as timings from '../utils/timings'
import { revealTextDecode } from '../utils/effects'
import { FullscreenVideo } from '../components/fullscreen-video'
import { ExternalLink, registerExternalLinksEffect } from '../components/external-link'

gsap.registerPlugin(ScrollTrigger)

let lenis: Lenis | null = null

const initializeGsap = async () => {
  // lenis scroll setup
  lenis = new Lenis({
    duration: timings.SCROLL_DURATION,
    touchMultiplier: 5,
  })
  lenis.on('scroll', ScrollTrigger.update)
  gsap.ticker.add((time) => {
    lenis?.raf(time * timings.SCROLL_SMOOTHNESS)
  })
}

export function HomePage() {
  useAnalyticsPageView(process.env.NEXT_PUBLIC_GA_ID as string)

  const digitalPulse = useScramble({ text: 'Digital pulse', speed: 0.25, playOnMount: false })
  const humanSoul = useScramble({
    text: 'Human soul',
    speed: 0.25,
    onAnimationEnd: () => {
      digitalPulse.replay()
    },
  })

  const footer6 = useScramble({ text: 'MMXXIII', speed: 1, playOnMount: false })
  const footer5 = useScramble({
    text: 'Est.',
    speed: 1,
    playOnMount: false,
    onAnimationEnd: () => {
      footer6.replay()
    },
  })
  const footer4 = useScramble({
    text: '&copy; 1337AI, Inc.',
    speed: 1,
    playOnMount: false,
    onAnimationEnd: () => {
      footer5.replay()
    },
  })
  const footer3 = useScramble({
    text: 'Contact',
    speed: 1,
    playOnMount: false,
    onAnimationEnd: () => {
      footer4.replay()
    },
  })
  const footer2 = useScramble({
    text: 'LinkedIn',
    speed: 1,
    playOnMount: false,
    onAnimationEnd: () => {
      footer3.replay()
    },
  })

  const footer1 = useScramble({
    text: 'Instagram',
    speed: 1,
    playOnMount: false,
    onAnimationEnd: () => {
      footer2.replay()
    },
  })

  useEffect(() => {
    SplitType.create('.split-text', {
      types: 'words',
    })

    initializeGsap().then(() => {
      registerExternalLinksEffect('.link-external')
      revealTextDecode('#content')

      ScrollTrigger.create({
        trigger: `#footer`,
        start: 'top bottom',
        once: true,
        onEnter: ({ isActive }) => {
          if (isActive) {
            footer1.replay()
          }
        },
      })
    })

    const landingAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: '#landing',
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        pinSpacing: false,
      },
    })

    gsap.utils.toArray<HTMLElement>('section').forEach((layer) => {
      const depth = parseFloat(layer.dataset.depth ?? '0')
      const movement = -(layer.offsetHeight * depth)
      landingAnimation.to(layer, { top: movement, ease: 'none' }, 0)
    })

    const footerVideoAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: '#footer-video',
        start: 'top 100%',
        end: 'bottom bottom',
        scrub: true,
        pinSpacing: false,
      },
    })

    footerVideoAnimation.fromTo(
      '#footer-video > div',
      {
        yPercent: -50,
      },
      {
        yPercent: 0,
      },
    )

    gsap.to('#heading-cover', {
      scrollTrigger: {
        trigger: `#footer`,
        start: 'top bottom',
        toggleActions: 'play none none reverse',
        scrub: true,
      },
      opacity: 0,
    })

    gsap.to('#video', {
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: `#footer`,
        start: 'top center',
        end: 'bottom center',
        once: true,
      },
      onStart: () => {
        gsap
          .to('#human-soul', {
            opacity: 1,
            delay: 0.8,
            duration: 1,
            scrollTrigger: {
              trigger: `#footer`,
              start: 'top center',
              end: 'bottom center',
            },
            onStart: () => {
              humanSoul.replay()
            },
          })
          .then(() => {
            gsap.to('#digital-pulse', {
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: `#footer`,
                start: 'top center',
                end: 'bottom center',
              },
              onStart: () => {
                digitalPulse.replay()
              },
            })
          })
      },
    })
  }, [])

  const handleFullscreenOpenChange = (open: boolean) => {
    if (!lenis) {
      console.warn('Lenis not initialized')
      return
    }

    if (open) {
      lenis.stop()
    } else {
      lenis.start()
    }
  }

  return (
    <div id="landing" className="flex flex-col bg-black">
      <section
        data-depth="-1.00"
        className="z-1 relative flex flex-col bg-leet justify-between h-[100vh]"
        id="loop"
      >
        <FullscreenVideo onOpenChange={handleFullscreenOpenChange} />
      </section>

      <section data-depth="1.00" className="z-[8] flex flex-col bg-leet justify-between h-[100vh]">
        <div id="content" className="flex flex-col lg:flex-row">
          <div className="lg:w-[58vw]">
            <img
              src="/logo.svg"
              alt="logo"
              className="w-[13rem] lg:w-[21vw] object-contain mt-[0.25rem] ml-[0.25rem]"
            />
          </div>
          <div className="flex flex-col font-body font-bold text-sm lg:text-[1.337vw] flex-1 lg:max-w-[30vw] gap-[0.25rem] leading-none m-[0.25rem]">
            <p className="split-text">
              <span className="w-[40px] lg:indent-[2vw] inline-block" />
              Human + AI = the new power couple. That's our core belief. Forget the old ways of
              content creation; the future is a clash of human creativity and AI power. Norms?
              Smashed. New possibilities? Endless.
            </p>
            <p className="split-text">
              <span className="w-[40px] lg:indent-[2vw] inline-block" />
              Imagine this—
            </p>
            <p className="split-text">
              by the decade's end, solopreneurs commanding billion-dollar synthetic media empires
              like it's no big deal.
            </p>
            <p className="split-text">
              <span className="w-[40px] lg:indent-[2vw] inline-block" />
              But prompting is so last year. Tired of endless prompt battles? We feel you, we're
              over those too.
            </p>
            <p className="split-text">
              <span className="w-[40px] lg:indent-[2vw] inline-block" />
              Instead of chaos, our engine conjures up content that slaps and is ready to go.
            </p>
            <p className="split-text">
              <span className="w-[40px] lg:indent-[2vw] inline-block" />
              S1MSTA is a content revolution the internet didn’t see coming. It's the secret weapon
              for operating AI influencers and scaling content creation like never before. Ready to
              shake things up? Lez f—cking GOOOO.
            </p>
            <div className="flex flex-col gap-[0.25rem] mt-[1rem] lg:mt-[0.5rem]">
              <div>
                <span className="w-[40px] lg:indent-[2vw] inline-block" />
                <ExternalLink
                  size="link"
                  className="overflow-visible uppercase"
                  href="https://www.loom.com/share/b26ef2d0716346b4acec4a274c35a22f?sid=d4473d33-31ed-4e21-9a09-f7e407421049"
                >
                  S1MSTA demo
                </ExternalLink>
              </div>
              <div>
                <span className="w-[40px] lg:indent-[2vw] inline-block" />
                <ExternalLink
                  size="link"
                  className="overflow-visible uppercase"
                  href="https://forms.gle/KKVugjpmdKi1EzmX9"
                >
                  Get access
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>

        <div
          id="footer"
          className="flex flex-col gap-[0.25rem] lg:ml-[58vw] font-body font-bold text-sm lg:text-[1.337vw] leading-none m-[0.25rem]"
        >
          <div>
            <span className="inline-block w-[154px] lg:w-[8vw]">
              <a ref={footer1.ref} href="https://www.instagram.com/1337org/" target="_blank" />
            </span>
            <span className="inline-block">
              <a
                ref={footer2.ref}
                href="https://www.linkedin.com/company/1337org/"
                target="_blank"
              />
            </span>
          </div>
          <div>
            <span className="inline-block ml-[58px] lg:ml-[3vw] w-[134px] lg:w-[7vw]">
              <a ref={footer3.ref} href="mailto:hello@1337.org" />
            </span>
            <span ref={footer4.ref} className="inline-block" />
          </div>
          <div>
            <span ref={footer5.ref} className="inline-block w-[154px] lg:w-[8vw]" />
            <span ref={footer6.ref} className="inline-block" />
          </div>
        </div>
      </section>

      <section id="footer-video" className="z-4 relative overflow-hidden h-[100vh] bg-black">
        <div className="w-full h-full">
          <div className="vimeo-container pointer-events-none max-w-none z-[1]">
            <iframe
              id="video"
              src="https://player.vimeo.com/video/996603526?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1"
              allow="autoplay;"
              frameBorder="0"
              className="heading absolute object-cover opacity-0"
            ></iframe>

            <div className="heading h-[100vh] flex flex-col lg:flex-row items-center justify-center lg:justify-start z-[2] text-leet font-body font-black tracking-[-0.03em] text-[16vw] leading-[13vw] lg:text-[6vw] lg:leading-[6vw]">
              <div className="lg:w-[58vw] z-[2]">
                <span ref={humanSoul.ref} id="human-soul" className="whitespace-nowrap opacity-0" />
              </div>
              <div className="z-[2]">
                <span
                  ref={digitalPulse.ref}
                  id="digital-pulse"
                  className="whitespace-nowrap opacity-0"
                />
              </div>
            </div>
          </div>

          <div
            id="heading-cover"
            className="w-full h-full absolute bg-black left-0 top-0 z-[6] opacity-1"
          />
        </div>
      </section>
    </div>
  )
}

export default HomePage
