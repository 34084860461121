import gsap from 'gsap'

export type RevealTextTimelineOptions = {
  scrollTriggerExt?: ScrollTrigger.Vars
  revealExt?: gsap.TweenVars
}

export const revealTextDecode = (sectionId: string, options?: RevealTextTimelineOptions) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: sectionId,
        start: 'top 90%',
        toggleActions: 'play complete complete complete',
        ...options?.scrollTriggerExt,
      },
    })
    .from(`${sectionId} .word`, {
      duration: 0.1,
      opacity: 0,
      stagger: {
        each: 0.015,
        from: 'random',
      },
      ...options?.revealExt,
    })
}
