import { useState, useEffect } from 'react'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    // This code will only run on the client-side
    const checkIsMobile = (): boolean => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera

      // Android Mobile
      if (/android/i.test(userAgent)) {
        return true
      }

      // iOS Mobile
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return true
      }

      // Windows Phone
      if (/windows phone/i.test(userAgent)) {
        return true
      }

      // Other mobile user agents can be added here

      return false
    }

    setIsMobile(checkIsMobile())
  }, [])

  return isMobile
}
