// import Player from '@vimeo/player'
import gsap from 'gsap'
import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../hooks/use-is-mobile'
import { useIsSafariDesktop } from '../hooks/use-is-safari-desktop'
import Player from '@vimeo/player'
import { useScramble } from 'use-scramble'
import { useHotkeys } from 'react-hotkeys-hook'
import clsx from 'clsx'

export interface FullscreenVideoProps {
  onOpenChange: (open: boolean) => void
}

export const FullscreenVideo = ({ onOpenChange }: PropsWithChildren<FullscreenVideoProps>) => {
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const cursorRef = useRef<HTMLDivElement | null>(null)
  const videoOverlayRef = useRef<HTMLDivElement | null>(null)
  const fullscreenVideoContainerRef = useRef<HTMLDivElement | null>(null)
  const animationRef = useRef<gsap.core.Tween | null>(null)
  const isMobile = useIsMobile()
  const isSafariDesktop = useIsSafariDesktop()

  const previewVimeoPlayer = useRef<Player | null>(null)
  const vimeoPlayer = useRef<Player | null>(null)

  const [cursorEnabled, setCursorEnabled] = useState(true)
  const [fullscreenOn, setFullscreenOn] = useState(false)

  const [progress, setProgress] = useState(0)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoMuted, setVideoMuted] = useState(false)

  const [controlsShowable, setControlsShowable] = useState(false)

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  const showControls = useCallback(() => {
    if (!controlsShowable || !fullscreenOn) return

    if (animationRef.current) {
      animationRef.current.kill()
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    animationRef.current = gsap.to('#controls', {
      opacity: 1,
      pointerEvents: 'auto',
      duration: 0.3,
    })

    timeoutRef.current = setTimeout(() => {
      animationRef.current = gsap.to('#controls', {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.3,
      })
    }, 3000)
  }, [controlsShowable, fullscreenOn])

  const playVideoScramble = useScramble({ text: 'Click 2 play', speed: 0.15, playOnMount: false })

  useEffect(() => {
    gsap.fromTo('#fullscreen-preview', { opacity: 0 }, { opacity: 1, duration: 1 })
    gsap.fromTo('#preview-cursor', { opacity: 0 }, { delay: 1, opacity: 1, duration: 0.5 })

    setTimeout(() => playVideoScramble.replay(), 250)

    gsap.to('#loop-cover', {
      scrollTrigger: {
        trigger: `#content`,
        start: 'top bottom',
        toggleActions: 'play none none reverse',
        scrub: true,
      },
      opacity: 1,
    })
  }, [])

  const updateVideoProgress = (ev: Player.TimeEvent) => {
    if (vimeoPlayer.current) {
      setProgress(ev.percent * 100)
    }
  }

  const onVideoEnded = () => {
    setTimeout(() => {
      closeFullscreenVideo()
    }, 500)
  }

  const toggleVideoPlayback = () => {
    if (!vimeoPlayer.current) return

    if (videoPlaying) {
      vimeoPlayer.current.pause()
    } else {
      vimeoPlayer.current.play()
    }

    setVideoPlaying(!videoPlaying)
  }

  const toggleVideoMute = () => {
    if (!vimeoPlayer.current) return

    vimeoPlayer.current.setMuted(!videoMuted)
    setVideoMuted(!videoMuted)
  }

  const playFullscreenVideo = async () => {
    fullscreenVideoContainerRef.current?.addEventListener('mousemove', handleMouseMove)
    setFullscreenOn(true)

    if (vimeoPlayer.current) {
      vimeoPlayer.current.setCurrentTime(0)
      vimeoPlayer.current.play()

      if (isMobile) {
        vimeoPlayer.current.requestFullscreen()

        vimeoPlayer.current.on('fullscreenchange', (e) => {
          if (!e.fullscreen) {
            closeFullscreenVideo()
          }
        })
      }
    }

    // In with full video
    gsap
      .fromTo(
        '#fullscreen-video-container',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          pointerEvents: 'auto',
          duration: 0.1,
        },
      )
      .then(() => {
        // Play video and attach listener for progress bar
        if (vimeoPlayer.current) {
          vimeoPlayer.current.on('timeupdate', updateVideoProgress)
          vimeoPlayer.current.on('ended', onVideoEnded)
        }
      })

    onOpenChange(true)

    if (!isMobile) {
      setTimeout(() => {
        gsap
          .fromTo(
            '#controls',
            {
              opacity: 1,
            },
            {
              opacity: 0,
              pointerEvents: 'none',
              duration: 0.3,
            },
          )
          .then(() => {
            setControlsShowable(true)
          })
      }, 3000)
    }
  }

  const closeFullscreenVideo = () => {
    fullscreenVideoContainerRef.current?.removeEventListener('mousemove', handleMouseMove)
    setControlsShowable(false)
    setFullscreenOn(false)

    vimeoPlayer.current?.pause()
    vimeoPlayer.current?.setCurrentTime(0)

    if (!isMobile) {
      const cursor = cursorRef.current
      const mouseX = windowSize.width / 2
      const mouseY = windowSize.height / 2

      gsap.set(cursor, { xPercent: -50, yPercent: -50 })
      gsap.set(cursor, { x: mouseX, y: mouseY })
    }

    // Out with full video
    gsap
      .fromTo(
        '#fullscreen-video-container',
        {
          opacity: 1,
        },
        {
          opacity: 0,
          pointerEvents: 'none',
          duration: isMobile ? 0.1 : 0.5,
          delay: isMobile ? 0.45 : 0,
        },
      )
      .then(() => {
        // Stop video and remove listener
        if (vimeoPlayer.current) {
          vimeoPlayer.current.off('timeupdate', updateVideoProgress)
          vimeoPlayer.current.off('ended', onVideoEnded)

          vimeoPlayer.current.pause()
          vimeoPlayer.current.setCurrentTime(0)

          animationRef.current?.kill()
          gsap.set('#controls', { opacity: 1 })
        }
      })

    onOpenChange(false)
  }

  const onScrollChanged = () => {
    if (window.scrollY < 30) {
      setCursorEnabled(true)
    } else {
      setCursorEnabled(false)
    }
  }

  useEffect(() => {
    onScrollChanged()

    window.addEventListener('scroll', onScrollChanged)

    return () => {
      window.removeEventListener('scroll', onScrollChanged)
    }
  }, [])

  useEffect(() => {
    const currentOverlayRef = videoOverlayRef.current

    if (!currentOverlayRef) {
      return
    }

    const onScrollAttempt = (event: WheelEvent) => {
      if (!fullscreenOn) {
        return
      }

      const delta = event.deltaY || event.deltaX || 0

      if (delta > 0) {
        // User is trying to scroll down
        closeFullscreenVideo()
      }
    }

    currentOverlayRef.addEventListener('wheel', onScrollAttempt)

    return () => {
      currentOverlayRef.removeEventListener('wheel', onScrollAttempt)
    }
  }, [fullscreenOn])

  const handleMouseMove = useCallback(() => {
    showControls()
  }, [showControls])

  useEffect(() => {
    const container = fullscreenVideoContainerRef.current

    if (!container) return

    container.addEventListener('mousemove', handleMouseMove)

    return () => {
      container.removeEventListener('mousemove', handleMouseMove)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      if (animationRef.current) animationRef.current.kill()
    }
  }, [controlsShowable, fullscreenOn, handleMouseMove])

  useEffect(() => {
    const updateWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    updateWindowSize()

    window.addEventListener('resize', updateWindowSize)

    return () => window.removeEventListener('resize', updateWindowSize)
  }, [])

  const registerCursor = useCallback(() => {
    const cursor = cursorRef.current
    let mouseX = windowSize.width / 2
    let mouseY = windowSize.height / 2

    gsap.set(cursor, { xPercent: -50, yPercent: -50 })
    gsap.set(cursor, { x: mouseX, y: mouseY })

    const onMouseMove = (e: MouseEvent) => {
      if (!isMobile) {
        mouseX = e.clientX
        mouseY = e.clientY
      }
    }

    window.addEventListener('mousemove', onMouseMove)

    const animateCursor = () => {
      if (!fullscreenOn) {
        gsap.to(cursor, {
          duration: 0.5,
          x: mouseX,
          y: mouseY,
          ease: 'power2.out',
        })
      }
    }

    gsap.ticker.add(animateCursor)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      gsap.ticker.remove(animateCursor)
    }
  }, [windowSize, fullscreenOn])

  useEffect(() => {
    const fullscreenMain = document.querySelector('#fullscreen-main') as HTMLElement
    if (fullscreenMain) {
      vimeoPlayer.current = new Player(fullscreenMain)
      vimeoPlayer.current.on('fullscreenchange', (e) => {
        if (isMobile && !e.fullscreen) {
          closeFullscreenVideo()
        }
      })
    }

    const previewVideo = document.querySelector('#preview-video') as HTMLElement
    if (previewVideo) {
      previewVimeoPlayer.current = new Player(previewVideo)
      previewVimeoPlayer.current.on('loaded', (e) => {
        gsap.to('#preview-image', { opacity: 0, duration: 0.1 })
        previewVimeoPlayer.current?.play()
      })
    }
  }, [])

  useEffect(() => {
    const unregister = registerCursor()

    return () => {
      unregister?.()
    }
  }, [registerCursor])

  useEffect(() => {
    if (cursorEnabled) {
      gsap.to('#play-video-btn', { opacity: 1, duration: 0.5 })
    } else {
      gsap.to('#play-video-btn', { opacity: 0, duration: 0.5 })
    }
  }, [cursorEnabled])

  useHotkeys('esc', closeFullscreenVideo)

  return (
    <section className="absolute h-[100vh] w-[100dvw] bg-black overflow-hidden">
      <div className="absolute h-[100dvh] w-[100dvw]">
        <div
          id="preview-cursor"
          className={clsx('absolute z-[3]', {
            'opacity-100': cursorEnabled,
            'opacity-0': !cursorEnabled,
          })}
          ref={cursorRef}
        >
          <h2
            id="play-video-btn"
            className={clsx({
              'text-leet font-body font-black tracking-[-0.05em] z-[3] select-none opacity-100 text-[16vw] leading-[13vw] lg:text-[6vw] lg:leading-[6vw]':
                true,
              'cursor-none': cursorEnabled,
            })}
            onClick={playFullscreenVideo}
            ref={playVideoScramble.ref}
          />
        </div>
      </div>

      <div
        id="preview-container"
        className={clsx({
          'absolute flex items-center justify-center w-full h-full bg-black': true,
          'cursor-none': cursorEnabled,
        })}
      >
        <div className="vimeo-container pointer-events-none" id="fullscreen-preview">
          <iframe
            id="preview-video"
            src="https://player.vimeo.com/video/996192104?badge=0&amp;autopause=0&amp;autoplay=0&amp;player_id=0&amp;app_id=58479&background=1&quality=1080p"
            allow="autoplay;"
            frameBorder="0"
          ></iframe>
          <img
            src="/1337-preview.jpg"
            id="preview-image"
            alt=""
            className="w-full h-full left-0 top-0 absolute opacity-100 object-cover"
          />
        </div>

        <div
          id="fullscreen-preview-overlay"
          className="absolute w-full h-full bg-black opacity-30 z-[2]"
        ></div>

        <div
          id="loop-cover"
          className="w-full h-full absolute bg-black left-0 top-0 z-[4] opacity-0 pointer-events-none"
        />
      </div>

      <div
        id="fullscreen-video-container"
        ref={fullscreenVideoContainerRef}
        className="absolute w-full h-full opacity-0 z-[4] pointer-events-none bg-black"
      >
        <iframe
          id="fullscreen-main"
          src="https://player.vimeo.com/video/996191758?badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479&title=0&byline=0&portrait=0&&controls=0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          className={clsx({
            'absolute top-0 left-0 w-full h-full': true,
            hidden: isSafariDesktop && !fullscreenOn,
          })}
        ></iframe>

        {!isMobile && (
          <div className="w-full h-full relative" ref={videoOverlayRef}>
            <div aria-label="video controls" className="absolute top-0 w-full z-[5] p-[0.25rem]">
              <div
                id="controls"
                className="flex text-leet font-body font-bold tracking-[-0.03em] select-none cursor-pointer uppercase leading-none mx-[0.25rem] text-sm lg:text-[1.337vw] "
              >
                <p onClick={closeFullscreenVideo} className="flex-1 text-left">
                  Esc
                </p>
              </div>
            </div>
            <div
              aria-label="video controls"
              className="flex flex-col gap-[0.25rem] absolute bottom-0 w-full z-[5]"
            >
              <div
                id="controls"
                className="flex text-leet font-body font-bold tracking-[-0.03em] select-none cursor-pointer uppercase leading-none mx-[0.25rem] text-sm lg:text-[1.337vw] "
              >
                <p onClick={toggleVideoPlayback} className="flex-1 text-left">
                  {videoPlaying ? 'Pause' : 'Play'}
                </p>
                <p onClick={toggleVideoMute} className="flex-1 text-right">
                  {videoMuted ? 'Unmute' : 'Mute'}
                </p>
              </div>
              <div>
                <div className="bg-leet h-[0.25rem]" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
