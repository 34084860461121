import type { SVGProps } from 'react'
export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={32}
    fill="none"
    viewBox="0 0 37 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.176.824 4.608 4.752-7.536 7.2h23.808V19.4h-23.52l7.248 6.96-4.608 4.752L.104 15.944z"
    />
  </svg>
)
