import type { SVGProps } from 'react'

export const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 31" {...props}>
    <path
      fill="currentColor"
      d="M15.5 20.4497L25.3995 30.3492L30.3492 25.3995L20.4497 15.5L30.3492 5.60051L25.3995 0.650758L15.5 10.5503L5.60046 0.650759L0.650714 5.60051L10.5502 15.5L0.650715 25.3995L5.60046 30.3492L15.5 20.4497Z"
    />
  </svg>
)
