import type { SVGProps } from 'react'

export const Plus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 12" {...props}>
    <path
      fill="currentColor"
      d="M5.094 11.9099V7.35591H0.342V4.71591H5.094V0.0739111H7.844V4.71591H12.574V7.35591H7.844V11.9099H5.094Z"
    />
  </svg>
)
