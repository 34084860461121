import type { SVGProps } from 'react'

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 18" {...props}>
    <path
      fill="currentColor"
      d="M7.8 17.4599L0.936 10.5959L2.762 8.76991L6.48 12.5759V0.959912H9.098V12.5979L12.838 8.76991L14.686 10.5959L7.8 17.4599Z"
    />
  </svg>
)
