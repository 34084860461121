import type { SVGProps } from 'react'

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13" {...props}>
    <path
      fill="currentColor"
      d="m8.84.34 6.22 6.24-6.22 6.26-1.66-1.72 3.42-3.3H0v-2.4h10.66L7.18 2.06 8.84.34Z"
    />
  </svg>
)
