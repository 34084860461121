import type { HTMLAttributes, PropsWithChildren } from 'react'
import { ArrowExternal } from '@1337-us/ui-icons'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import gsap from 'gsap'

export interface ExternalLinkProps extends HTMLAttributes<HTMLSpanElement> {
  href?: string
  size?: 'small' | 'large' | 'xlarge' | 'xsmall' | 'link'
  className?: string
  arrowClassName?: string
  hideDefault?: boolean
}

export const ExternalLink = ({
  href,
  children,
  className,
  arrowClassName,
  hideDefault,
  size = 'large',
  ...rest
}: PropsWithChildren<ExternalLinkProps>) => {
  const linkWrapperClasses = clsx({
    'font-body font-bold uppercase overflow-hidden': true,
    'text-m-base lg:text-d-2base': size === 'small',
    'text-m-2lg lg:text-d-2lg': size === 'xlarge',
    'text-m-lg lg:text-d-lg-tight': size === 'large',
    'text-m-xs lg:text-d-sm': size === 'xsmall',
    'font-body font-bold tracking-[-0.03em]': size === 'link',
  })

  const arrowClasses = clsx({
    arrow: true,
    'h-[8vw] lg:h-[4vw]': size === 'xlarge',
    'h-[4vw] lg:h-[2vw]': size === 'large',
    'h-[3.4vw] lg:h-[0.75vw]': size === 'small',
    'h-[1.8vw] lg:h-[0.75vw]': size === 'xsmall',
    'h-[0.75rem] lg:h-[1vw]': size === 'link',
  })

  return (
    <span className={twMerge(linkWrapperClasses, className)} {...rest}>
      <a
        className={clsx('link-external inline-flex items-center group', {
          'hide-default': hideDefault,
        })}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <div className="overflow-hidden w-[0] group-hover:w-[1rem] group-hover:lg:w-[1.5vw] transition-all duration-[300ms] lg:duration-[350ms] mt-[-0.5px] lg:mt-[-1px]">
          <ArrowExternal className={twMerge(arrowClasses, arrowClassName)} />
        </div>
        {children}
      </a>
    </span>
  )
}

export const registerExternalLinksEffect = (
  selector: string,
  parentSelector?: string,
  useScrollTrigger = true,
  arrowSelector = '.arrow',
  // defaultShow = true,
) => {
  const parent = (parentSelector ? document.querySelector(parentSelector) : null) ?? document
  const workLinks = parent.querySelectorAll(selector)

  workLinks.forEach((link) => {
    const arrow = link.querySelector(arrowSelector)
    const defaultShow = !link.classList.contains('hide-default')

    const linkTimeline = gsap.timeline({
      scrollTrigger:
        defaultShow && useScrollTrigger
          ? {
              trigger: arrow,
              start: 'top 95%',
              toggleActions: 'play complete complete complete',
            }
          : null,
    })

    if (defaultShow) {
      linkTimeline.set(arrow, {
        xPercent: 0,
        yPercent: 0,
      })

      linkTimeline.to(arrow, {
        xPercent: 100,
        yPercent: -100,
        duration: 0.25,
      })

      linkTimeline.set(arrow, {
        xPercent: -100,
        yPercent: 100,
      })

      linkTimeline.to(arrow, {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
      })
    } else {
      linkTimeline.set(arrow, {
        xPercent: -100,
        yPercent: 100,
      })

      linkTimeline.to(arrow, {
        xPercent: 0,
        yPercent: 0,
        duration: 0.25,
      })

      linkTimeline.add('midway')

      linkTimeline.to(arrow, {
        xPercent: 100,
        yPercent: -100,
      })
    }

    link.addEventListener('mouseenter', (e) => {
      if (defaultShow) {
        linkTimeline.restart()
      } else {
        linkTimeline.tweenFromTo(0, 'midway')
      }
    })

    link.addEventListener('mouseleave', (e) => {
      if (!defaultShow) {
        linkTimeline.play()
      }
    })
  })
}
