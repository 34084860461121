import { useEffect, useState } from 'react'

export function useIsSafariDesktop(): boolean {
  const [isSafariDesktop, setIsSafariDesktop] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Ensure this runs only in the browser
      const userAgent = navigator.userAgent.toLowerCase()
      const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome')
      const isDesktop = !/iphone|ipad|ipod|android/.test(userAgent)

      setIsSafariDesktop(isSafari && isDesktop)
    }
  }, [])

  return isSafariDesktop
}
