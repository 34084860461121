import type { SVGProps } from 'react'

export const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={11}
    fill="none"
    viewBox="0 0 15 11"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2.75} d="m1 4 5 5 8-8" />
  </svg>
)
