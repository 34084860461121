import type { SVGProps } from 'react'

export const LogoFull = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 505 87" {...props}>
    <path
      fill="currentColor"
      d="M53.572 15.818c4.37 0 9.953-3.54 12.476-7.909C68.571 3.54 74.155 0 78.525 0h23.727c4.366 0 5.865 3.54 3.342 7.91L64.498 79.09C61.975 83.46 56.391 87 52.021 87H4.568C.2 87-1.298 83.46 1.225 79.09l31.961-55.363c2.523-4.37 8.107-7.909 12.477-7.909h7.909Z"
    />
    <path
      fill="currentColor"
      d="m371.912 15.818 4.568-7.909C379.003 3.54 384.587 0 388.956 0h110.728c4.365 0 5.864 3.54 3.341 7.91l-10.408 18.024c-1.966 3.4-5.889 6.426-9.74 7.502l-71.985 20.12c-3.851 1.076-7.774 4.097-9.74 7.502l-10.408 18.025c-2.523 4.37-8.107 7.91-12.477 7.91h-39.545c-4.366 0-5.865-3.54-3.342-7.91l10.409-18.025c1.965-3.4 5.888-6.426 9.74-7.502l71.984-20.12c3.852-1.076 7.775-4.097 9.74-7.502l5.841-10.116c2.523-4.37 1.028-7.909-3.341-7.909-4.37 0-9.954 3.54-12.477 7.91-2.523 4.369-8.107 7.908-12.477 7.908h-39.545c-4.366 0-5.865-3.539-3.342-7.909Z"
    />
    <path
      fill="currentColor"
      d="m228.94 23.727 9.131-15.818C240.594 3.54 239.095 0 234.729 0H124.002c-4.37 0-9.954 3.54-12.477 7.91l-4.567 7.908c-2.523 4.37-1.024 7.91 3.342 7.91h39.545c4.37 0 9.954-3.54 12.477-7.91 2.523-4.37 8.106-7.909 12.476-7.909 4.37 0 5.865 3.54 3.342 7.91l-4.568 7.908c-2.523 4.37-8.107 7.91-12.476 7.91h-3.955c-2.183 0-4.975 1.771-6.236 3.954-1.262 2.183-.514 3.954 1.673 3.954h3.954c4.37 0 5.865 3.54 3.342 7.91l-13.699 23.727c-2.523 4.37-8.107 7.909-12.476 7.909-4.37 0-5.865-3.54-3.342-7.91l9.131-15.817c2.523-4.37 1.024-7.91-3.342-7.91H96.601c-4.37 0-9.954 3.54-12.477 7.91L70.426 79.09C67.903 83.46 69.402 87 73.767 87h110.728c4.369 0 9.953-3.54 12.476-7.91l18.266-31.636c2.523-4.37 1.025-7.909-3.341-7.909h-3.955c-2.183 0-2.934-1.771-1.673-3.954 1.262-2.183 4.054-3.955 6.237-3.955h3.954c4.37 0 9.954-3.539 12.477-7.909h.004Z"
    />
    <path
      fill="currentColor"
      d="m370.548 7.91-9.131 15.817h-.004c-2.523 4.37-8.107 7.91-12.477 7.91h-3.954c-2.183 0-4.975 1.771-6.236 3.954-1.262 2.183-.511 3.954 1.672 3.954h3.955c4.366 0 5.864 3.54 3.341 7.91L329.448 79.09C326.925 83.46 321.342 87 316.972 87H206.245c-4.366 0-5.865-3.54-3.342-7.91l13.699-23.726c2.523-4.37 8.106-7.91 12.476-7.91h39.546c4.365 0 5.864 3.54 3.341 7.91l-9.131 15.818c-2.523 4.37-1.028 7.909 3.342 7.909s9.953-3.54 12.476-7.91l13.699-23.726c2.523-4.37 1.028-7.91-3.342-7.91h-3.954c-2.187 0-2.935-1.771-1.673-3.954 1.261-2.183 4.053-3.955 6.236-3.955h3.955c4.37 0 9.953-3.539 12.476-7.909l4.568-7.909c2.523-4.37 1.028-7.909-3.342-7.909-4.369 0-9.953 3.54-12.476 7.91-2.523 4.369-8.107 7.908-12.477 7.908h-39.545c-4.366 0-5.865-3.539-3.342-7.909l4.568-7.909C246.526 3.54 252.109 0 256.479 0h110.727c4.366 0 5.865 3.54 3.342 7.91Z"
    />
    <path
      fill="currentColor"
      d="M401.971 77.904V87h2.721v-9.096h3.685v-2.372h-10.076v2.373h3.67Z"
    />
    <path
      fill="currentColor"
      d="M414.712 87h2.547l2.562-9.19V87h2.626V75.532h-4.224l-2.246 8.225-2.199-8.225h-4.27V87h2.625v-9.174L414.712 87Z"
    />
  </svg>
)
