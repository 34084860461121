import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useAnalyticsPageView = (measurementId: string) => {
  const router = useRouter()

  useEffect(() => {
    if (!window.gtag) {
      return
    }

    const handleRouteChange = (url: string) => {
      gtag('config', measurementId, {
        page_path: url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, measurementId])
}
